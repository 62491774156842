<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
        >
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <!-- 基本信息 -->
              <!-- <div class="form-label-base">企业安全自查</div> -->
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="养护企业" prop="careUnitName">
                <el-select v-model="form.careUnitName" placeholder="请选择">
                  <el-option
                    v-for="item in careUnitNameOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="标段名称" prop="bidId">
                <el-cascader
                  v-model="form.bidId"
                  placeholder="请选择"
                  :options="bidList"
                  :show-all-levels="false"
                  filterable
                  @change="hanldBidId"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="自查日期" prop="checkDate">
                <el-date-picker
                  v-model="form.checkDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="timestamp"
                ></el-date-picker>
              </el-form-item>
            </el-col>

            <el-col class="time-scoped" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="自查时间" prop="checkTime">
                <el-time-select
                  placeholder="起始时间"
                  v-model="form.checkTime[0]"
                  :picker-options="{
                    start: '00:00',
                    step: '00:05',
                    end: '23:55'
                  }"
                ></el-time-select>
                <el-time-select
                  placeholder="结束时间"
                  v-model="form.checkTime[1]"
                  :picker-options="{
                    start: '00:00',
                    step: '00:05',
                    end: '23:55',
                    minTime: form.checkTime[0]
                  }"
                ></el-time-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="人员" prop="checkName">
                <el-input v-model="form.checkName" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="联系方式" prop="checkPhone">
                <el-input v-model="form.checkPhone" placeholder="请输入" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="备注">
                <el-input v-model="form.checkRemark" placeholder="请输入" maxlength="25"></el-input>
              </el-form-item>
            </el-col>

            <el-form-item label="自查结果资料" style="width: 100%">
              <ayl-upload-files
                v-model="form.checkDocUrls"
                :limit="3"
                :maxSize="10240"
                :accept="''"
              ></ayl-upload-files>
            </el-form-item>

            <el-form-item label="自查整改情况" style="width: 100%">
              <ayl-upload-files
                v-model="form.checkReformUrls"
                :limit="3"
                :maxSize="10240"
                :accept="''"
              ></ayl-upload-files>
            </el-form-item>

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="自查照片" style="width: 100%">
                <ayl-img-upload
                  v-model="seekList"
                  :imgWidth="'130px'"
                  :imgHeight="'130px'"
                  :imgListLength="50"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">提交</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      nav: [{ name: "新增企业安全自查" }],
      bidList: [],
      careUnitNameOption: [],
      isLoading: false,
      form: {
        careUnitName: null, //养护企业
        bidId: null, //标段名称
        checkDate: null, //自查日期
        checkTime: [], //自查日期
        checkStartTime: null, //自查开始时间
        checkEndTime: null, // 自查结束时间
        checkName: null, //自查人员
        checkPhone: null, //自查人员联系方式
        checkRemark: null, //备注
        checkDocUrls: [], //自查结果资料
        checkReformUrls: [], //自查整改情况
        checkImageUrls: null //自查照片
      },
      // checkDocFiles: [],
      // checkReformFiles: [{ name: null, url: null }],
      // 自查照片
      seekList: [],

      // 必填字段校验
      rules: {
        careUnitName: {
          required: true,
          message: "请输入养护企业",
          trigger: "change"
        },
        bidId: {
          required: true,
          message: "请选择标段",
          trigger: "change"
        },
        checkDate: {
          required: true,
          message: "请选择自查日期",
          trigger: "change"
        },
        checkTime: {
          validator: (rule, value, callback) => {
            if (!value[0] || !value[1]) {
              callback(new Error("请选择自查时间"));
            } else {
              let s1 = parseFloat(value[0].split(":")[0]);
              let s2 = parseFloat(value[0].split(":")[1]);
              let e1 = parseFloat(value[1].split(":")[0]);
              let e2 = parseFloat(value[1].split(":")[1]);
              if (s1 > e1 || (s1 >= e1 && s2 >= e2)) {
                callback(new Error("请选择自查时间"));
              } else {
                callback();
              }
            }
          },
          required: true,
          message: "请选择自查时间",
          trigger: "change"
        },
        checkName: {
          required: true,
          message: "请输入自查人员姓名",
          trigger: "blur"
        },
        checkPhone: {
          required: true,
          message: "请输入自查人员电话号码",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    hanldBidId(arr) {
      this.form.bidId = arr[1];
    },
    //关闭
    onClose() {
      BUS.$emit(BUSEVENT.REFRESH_SAFETY_CHECK);
      this.$emit("onClose"); //关闭弹窗
      this.$refs["form"].resetFields();
    },

    async submit() {
      let params = JSON.parse(JSON.stringify(this.form));
      delete params.checkTime;
      params.checkDate = this.form.checkDate + "";
      params.checkStartTime = this.form.checkTime[0];
      params.checkEndTime = this.form.checkTime[1];
      params.checkDocUrls = JSON.stringify(this.form.checkDocUrls);
      params.checkReformUrls = JSON.stringify(this.form.checkReformUrls);
      params.checkImageUrls = this.seekList.join(",");
      console.log(params);
      await this.$api_hw
        .operationSafetyManagement_saveNewSelfCheckInfo({
          sanitationSafeCheckVO: params
        })
        .then(res => {
          this.$notify({
            title: "成功",
            message: `${this.nav[0].name}成功`,
            type: "success"
          });
          this.onClose();
        });
    },
    async onSubmit() {
      if (self.isLoading) return; //防止重复提交
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        this.isLoading = true;
        try {
          await this.submit();
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading = false;
        }
      });
    }
  },
  async mounted() {
    //获取所属标段下拉
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
    // 获取养护
    await this.$api_hw.districtInfo_getDistinctCareUnit({}).then(res => {
      this.careUnitNameOption = res.map(item => {
        return { value: item, label: item };
      });
    });
  }
};
</script>

<style lang='sass' scoped>
.content-box 
  padding: 0
.btn-tips
  display: flex;
  item-content: cengter
/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item,
/deep/ .el-select
  display: flex;
  width: 100%;
/deep/ .el-cascader--mini,
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  line-height: 34px;

.time-scoped /deep/ .el-form-item--mini .el-form-item__content
  display: flex;
</style>